import moment from 'moment'
import $ from 'jquery'
import * as BatchHelpers from 'utils/common/batch'
// UTILS
import {
  objectToFormData,
  isCustomerEditBooking,
  convertCustomReimbursementToParam,
  convertBookingCustomReimbursementToParam,
  isCancelToEdit,
} from 'utils/new_booking/common'
import { CPODUtils } from 'utils/booking/CPODUtils'
import { getDeviceId } from 'utils/cookie'

// API
// ACTIONS
// COMPONENTS
// CONSTANTS
import { FULL_DAY, LONG_HAUL, NOW } from 'constants/bookingConstants'
import { TALLY_API_URL, LTL_API_URL, DELIVEREE_PLACES_SERVICE_URL, API_GATEWAY } from 'constants/appConstants'
import { EXTRA_BY_OPTION } from 'constants/extraServiceConstants'
import { isBookAgain, isEditBooking, isPaymentBooking, redirectToSelectArea } from 'utils/booking/common'
import {
  assign,
  compact,
  filter,
  get,
  includes,
  isEmpty,
  isNull,
  isUndefined,
  map,
  pick,
  size,
  toInteger,
} from 'lodash'
import apiClient from 'services/axiosApp'
import Utils from 'utils/Utils'
import I18n from 'i18n/i18n'
import _ from 'lodash-es'
import { DRIVER_PREFERENCES } from 'constants/newBookingConstants'

// ASSETS

export const STEP_1_PAYLOAD_FIELDS = [
  'company_id',
  'locations_attributes',
  'vehicle_type_id',
  'round_trip_discount',
  'time_type',
  'pickup_time',
  'quick_choice_id',
  'flow',
  'eta_locations_id',
]

const language = {
  vi: {
    currentLanguage: 'vi-VN',
  },
  th: {
    currentLanguage: 'th-TH',
  },
  id: {
    currentLanguage: 'id-ID',
  },
  en: {
    currentLanguage: 'en-US',
  },
  tl: {
    currentLanguage: 'en-US',
  },
}

const dataReimbursement = (timeType, companySettings) => ({
  allowParkingFees: timeType === LONG_HAUL ? false : companySettings.allow_parking_fees,
  allowTollsFees: timeType === LONG_HAUL ? false : companySettings.allow_tolls_fees,
  allowWaitingTimeFees: companySettings.allow_waiting_time_fees,
})

const locationsAttributes = (locations, isCeb) => (
  locations.map((location) => {
    const extraPerLocations = filter(location.extra_requirement_locations, (e) => e.selected_amount > 0)
    const result = {
      latitude: location.lat,
      longitude: location.lng,
      need_cod: location.need_cod,
      need_pod: location.need_pod,
      cod_invoice_fees: location.cod_invoice_fees,
      extra_requirement_locations_attributes: extraPerLocations,
      address_components: location.address_components,
      name: location.name,
    }
    if (isCeb) {
      assign(result, {
        description: location.description,
        is_payer: location.is_payer,
        recipient_name: location.recipient_name,
        recipient_phone: location.recipient_phone,
        cod_note: location.cod_note,
        pod_note: location.pod_note,
      })
    }
    return result
  })
)
// It will be used for calculate and getTallyData functions
const generateRequestPayloadForCalculatingBooking = (state) => {
  if (!state.extraServices && !state.locations && !state.timeType) {
    return
  }
  const totalExtraRequirements = state.extraServices.extraRequirements.concat(
    state.extraServices.extraRequirementsNegativePosition
  )
  // for calculate API don't need marker
  const locations = filter(state.locations, (location) => location.lat && location.lng)
  const pickupTime = state.pickupTime
  const booking = state.booking
  const companySettings = state.extraServices.companySettings
  const allBadges = state.extraServices.companyBadges.concat(state.extraServices.vehicleTypeBadges)
  const { allowParkingFees, allowTollsFees, allowWaitingTimeFees } = dataReimbursement(state.timeType, companySettings)
  const customReimbursements = state.extraServices.customReimbursements
  const isCeb = isCustomerEditBooking()
  const fullDaySelectedAmount = state.extraServices.fullDayPricing.selected_amount
  const formData = {
    locations_attributes: locationsAttributes(locations, isCeb),
    company_id: state.currentCustomer.current_company_id,
    vehicle_type_id: state.selectedVehicleTypeID,
    // discount_code: state.discountCode,
    ...(state.discountCode && state.discountCode.value ? { discount_code: state.discountCode.value } : {}),
    round_trip_discount: state.roundTripDiscount,
    // time_type: state.timeType,
    ...(state.timeType ? { time_type: state.timeType } : {}),
    pickup_time: pickupTime,
    full_day_selected_amount: fullDaySelectedAmount || 1,
    booking_reimbursements_attributes: convertCustomReimbursementToParam(customReimbursements, state.timeType, state.extraInfos.enable_parking_tolls_feature),
    booking_extra_requirements_attributes: bookingExtraRequirementsAttributes(totalExtraRequirements, state.timeType),
    enable_quote: true,
    quote_id: (booking && booking.quote_id),
    ...(+state.quickChoiceID ? { quick_choice_id: state.quickChoiceID } : {}),
    ...(booking.eta_locations_id ? { eta_locations_id: booking.eta_locations_id } : {}),
    include: ['settlements', 'custom_reimbursements', 'reimbursements', 'show_special_settings']
  }
  if (isCancelToEdit()) formData.booking_id = state.bookAgainDetails.id
  if (isCeb) {
    if (isPaymentBooking(booking.payment_method_for_non_bp)) formData.include.push('payment')
    assign(formData, {
      is_ceb: isCeb,
      booking_id: state.bookAgainDetails.id,
      allow_parking_fees: allowParkingFees,
      allow_tolls_fees: allowTollsFees,
      allow_waiting_time_fees: allowWaitingTimeFees,
      note: state.note,
      job_order_number: state.jobOrderNumber,
      booking_badges_attributes: filter(allBadges, { selected: true }).map((badge) => ({
        badgeable_relation_id: badge.id,
        badgeable_relation_type: badge.badgeable_relation_type,
        selected_amount: badge.selected_amount,
      })),
    })
  }

  const bookingTrackingAttr = CPODUtils.validParamsBeforeCallAPI(state.documentReturn)
  if (size(bookingTrackingAttr) && CPODUtils.validateParamsDocumentReturn(locations, state.checkLocations)) {
    formData.booking_tracking_attributes = bookingTrackingAttr
  }

  if (state.bookAgainDetails?.batch_id) {
    formData.batch_id = state.bookAgainDetails?.batch_id
  }

  if (isBookAgain()) delete formData.booking_id

  return formData
}

const payloadTallyStep1 = (formData, timeType) => {
  let payload = {}
  if (timeType === FULL_DAY) {
    // to be consistent for step 1 full_day_selected_amount is always set 1
    payload = { full_day_selected_amount: 1 }
  }

  if (timeType === LONG_HAUL) {
    // because we want to calculate price of step 1 without extra_requirement_locations
    const locationsAttributes = formData.locations_attributes
    if (locationsAttributes) {
      payload = {
        locations_attributes: locationsAttributes.map((location) => {
          const extraRequirementLocationsAttrs = location.extra_requirement_locations_attributes
          if (extraRequirementLocationsAttrs && extraRequirementLocationsAttrs.length) {
            return {
              ...location,
              extra_requirement_locations_attributes: [],
            }
          }

          return location
        }),
      }
    }
  }
  return payload
}

const bookingExtraRequirementsAttributes = (totalExtraRequirements, timeType) =>
  filter(totalExtraRequirements, (extraRequirement) => {
    if (timeType === LONG_HAUL) {
      return extraRequirement.selected === true && extraRequirement.is_flat_per_location === false
    }
    return extraRequirement.selected === true
  }).map((extraRequirement) => {
    const result = {
      extra_requirement_id: extraRequirement.id,
      selected_amount: extraRequirement.selected_amount,
      is_flat: extraRequirement.is_flat,
      position: extraRequirement.position,
      unit_price: extraRequirement.unit_price,
    }
    if (!isUndefined(extraRequirement.selectedPricing) && extraRequirement.pricing_method === EXTRA_BY_OPTION) {
      result.unit_price = extraRequirement.selectedPricing.fees
      result.level_price = extraRequirement.selectedPricing.level_price
      result.extra_requirement_pricing_id = extraRequirement.selectedPricing.id
    }
    return result
  })

const payloadLocationAttributes = (booking, locations) =>
  locations.map((location) => {
    const extraPerLocations = filter(location.extra_requirement_locations, (e) => e.selected_amount > 0)
    const isPhoneMask = !isEmpty(location.phone_mask)
    const result = {
      latitude: location.lat,
      longitude: location.lng,
      name: location.name,
      extra_requirement_locations_attributes: extraPerLocations,
      description: location.description || '',
      is_payer: location.is_payer || false,
      recipient_name: location.recipient_name,
      recipient_phone: location.recipient_phone,
      need_cod: location.need_cod || false,
      need_pod: location.need_pod || false,
      cod_note: CPODUtils.getPramsCODPODNote(location, 'cod_note', booking),
      pod_note: CPODUtils.getPramsCODPODNote(location, 'pod_note', booking),
      cod_invoice_fees: location.cod_invoice_fees,
      contact_id: location.contact_id || null,
      is_phone_mask: isPhoneMask,
      location: location.location,
    }

    return result
  })

const payloadEstimateTransitTimesAttributes = (state, booking, pickupTime) => {
  const estimateTransitTimesAttributes = []
  // for 'quick' time type (timeType = 'now' and no quickChoice)
  if (state.timeType === NOW && !state.quickChoiceID) {
    pickupTime = moment().format()
  }

  if (pickupTime && booking.transit_time) {
    const estimateTransitTimes = {
      eta: moment(pickupTime).add(booking.transit_time, 's').format(),
    }
    if (booking.worst_transit_time) {
      estimateTransitTimes.worst_case_eta = moment(pickupTime).add(booking.worst_transit_time, 's').format()
    }

    estimateTransitTimesAttributes.push(estimateTransitTimes)
  }

  const hasEstimateTransitTime = estimateTransitTimesAttributes.length > 0
  return hasEstimateTransitTime ? { estimate_transit_times_attributes: estimateTransitTimesAttributes } : {}
}

const BookingAPI = {
  getExtraServices: async (
    authenticationToken,
    companyID,
    timeType,
    serviceTypeID,
    vehicleTypeID,
    locationCounts,
    callback
  ) => {
    const isFullDay = timeType === FULL_DAY
    const isLongHaul = timeType === LONG_HAUL
    const query = {
      company_id: companyID,
      service_type_id: serviceTypeID,
      vehicle_type_id: vehicleTypeID,
      is_full_day: isFullDay,
      is_long_haul: isLongHaul,
      dropoff_count: locationCounts - 1,
      time_type: timeType,
    }
    try {
      const res = await apiClient.get('/api/v3/bookings/extra_services', { params: query })
      return callback(res.data)
    } catch (err) {
      console.log(err)
    }
  },
  checkPricingService: (extraInfos) => {
    let isNewApi = false
    const isNewPricing = extraInfos?.enable_new_pricing_service
    const isNewEntry = extraInfos?.enable_new_booking_entry_service
    if (isNewPricing) {
      if (isEditBooking() && isNewEntry) {
        isNewApi = true
      } else if (!isEditBooking()) {
        isNewApi = true
      }
    }
    return isNewApi
  },
  calculate: async (state, callback) => {
    const { booking, extraInfos } = state
    const isNewApi = BookingAPI.checkPricingService(extraInfos)
    const formData = isEmpty(booking) ? {} : generateRequestPayloadForCalculatingBooking(state)
    const bankTransfer = booking?.bankTransfer
    const isVendorTipping = Boolean(booking.vendor_tipping_amount)

    if (!isEmpty(formData)) {
      const useCredit = get(booking, 'use_credit')
      formData.use_credit = isUndefined(useCredit) ? null : booking.use_credit
    }

    if (!_.isEmpty(bankTransfer) && !isCustomerEditBooking() && !isVendorTipping) {
      formData.payment_attributes = Utils.getPaymentAttributes(bankTransfer)
    }

    if (!isUndefined(formData) && !isEmpty(formData.locations_attributes) && !isUndefined(formData.vehicle_type_id)) {
      try {
        const res = await apiClient.post(isNewApi ? '/api/v4/pricing/calculate' : '/api/v3/bookings/calculate', formData, {
          headers: {
            'Device-Id': getDeviceId(),
          },
        })
        return callback(res.data)
      } catch (err) {
        throw new Error(err)
      }
    }
  },
  calculateCashback: async (state, callback, isServiceUpdate) => {
    const { booking, extraInfos } = state
  
    if (!extraInfos.at_least_once_cash_back_reward) {
      return callback(null)
    }

    const formData = generateRequestPayloadForCalculatingBooking(state)
    const cashBack = booking.cashBack || {}

    if (isEmpty(formData) || _.isEmpty(formData.locations_attributes) || _.isUndefined(formData.vehicle_type_id)) return

    const useCredit = _.get(booking, 'use_credit')
    formData.use_credit = _.isUndefined(useCredit) ? null : booking.use_credit
    formData.distance_fee_details = cashBack.distance_fee_details
    formData.is_in_extended_zone = cashBack.is_in_extended_zone
    const bankTransfer = booking?.bankTransfer

    if (!_.isEmpty(bankTransfer) && !isCustomerEditBooking()) {
      formData.payment_attributes = Utils.getPaymentAttributes(bankTransfer)
    }
    
    if (isServiceUpdate) {
      Object.assign(formData, {
        change_extra_service: true,
        distance_fees: cashBack.distance_fees,
        way_point_fees: cashBack.way_point_fees,
        assignable_booking_surcharges: cashBack.assignable_booking_surcharges,
        round_trip_discount_amount: cashBack.round_trip_discount_amount,
        out_of_service_area_fee: cashBack.out_of_service_area_fee,
      })
    } else {
      Object.assign(formData, {
        change_extra_service: false,
      })
    }
    
    try {
      const res = await apiClient.post('/api/v3/bookings/cash_back_amount_calculate', formData, {
        headers: {
          'Device-Id': getDeviceId(),
        },
      })
      if (res.data === null) {
        return callback(res.data)
      }
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  create: async (state, discountCodeIsInvalid, bookingAttachmentIds, callback) => {
    const totalExtraRequirements = state.extraServices.extraRequirements.concat(
      state.extraServices.extraRequirementsNegativePosition
    )
    const locations = filter(state.locations, (location) => location.marker !== undefined)
    const relatedAttachmentIds = compact(map(state.attachments, 'id'))
    const allBadges = state.extraServices.companyBadges.concat(state.extraServices.vehicleTypeBadges)
    const validDiscountCode = discountCodeIsInvalid ? '' : state.discountCode.value
    const companySettings = state.extraServices.companySettings
    const customReimbursements = state.booking.custom_reimbursements // customer reimbursement after calculate
    const pickupTime = state.pickupTime
    const { allowParkingFees, allowTollsFees, allowWaitingTimeFees } = dataReimbursement(state.timeType, companySettings)
    const booking = state.booking
    const estimateTransitTimesAttributes = payloadEstimateTransitTimesAttributes(state, booking, pickupTime)
    const allAttachmentIds = map(state.attachments, 'id')

    const params = {
      time_type: state.timeType,
      display_time_type: state.timeTypeUI,
      vehicle_type_id: state.selectedVehicleTypeID,
      service_type_id: state.selectedServiceTypeID,
      full_day_selected_amount: toInteger(state.extraServices.fullDayPricing.selected_amount),
      company_id: state.currentCustomer.current_company_id,
      pickup_time: pickupTime ? moment(pickupTime).format() : '',
      // discount_code: validDiscountCode || '',
      ...(validDiscountCode ? { discount_code: validDiscountCode } : {}),
      note: state.note,
      quick_choice_id: state.quickChoiceID,
      display_quick_choice_id: state.quickChoiceID,
      marked_as_favorite: false,
      round_trip_discount: state.roundTripDiscount,
      locations_attributes: payloadLocationAttributes(booking, locations),
      booking_extra_requirements_attributes: bookingExtraRequirementsAttributes(totalExtraRequirements, state.timeType),
      booking_badges_attributes: filter(allBadges, { selected: true }).map((badge) => {
        const result = {
          badgeable_relation_id: badge.id,
          badgeable_relation_type: badge.badgeable_relation_type,
          selected_amount: badge.selected_amount,
        }
        return result
      }),
      booking_reimbursements_attributes:
        state.timeType !== LONG_HAUL && !state.extraInfos.enable_parking_tolls_feature
          ? null
          : convertBookingCustomReimbursementToParam(customReimbursements),
      booking_attachment_ids: filter(bookingAttachmentIds, (x) => !includes(allAttachmentIds, x)),
      booking_tracking_attributes: CPODUtils.validateParamsDocumentReturn(locations, state.checkLocations)
        ? state.documentReturn
        : null,
      booking_location_tracking_attributes: state.currentCustomer.location_of_customer,
      related_attachment_ids: relatedAttachmentIds,
      just_signed_in: false,
      job_order_number: state.jobOrderNumber,
      allow_parking_fees: allowParkingFees,
      allow_tolls_fees: allowTollsFees,
      allow_waiting_time_fees: allowWaitingTimeFees,
      send_first_to_favorite: booking.driverPreferencesTab !== DRIVER_PREFERENCES.all,
      ...(booking && booking.quote_id && { quote_id: booking.quote_id }),
      assign_driver_booking_attributes: {
        driver_id: state.assignedDriver ? state.assignedDriver.id : null,
        fleet_partner_id: state.assignedDriver ? state.assignedDriver.fleet_partner_id : null,
      },
      previous_booking_id:
        state.isSavePreviousBookingID && state.bookAgainDetails ? state.bookAgainDetails.booking_code : null,
      has_settlement: !isEmpty(booking.settlement_details),
      require_signatures: state.requireSignatures,
      use_credit: booking.use_credit,
      ...estimateTransitTimesAttributes,
      ...(booking.eta_locations_id ? { eta_locations_id: booking.eta_locations_id } : {}),
      ...(!isEmpty(state.subAccountTagPicked)
        ? {
            sub_account_tag_attributes: {
              sub_account_id: state.subAccountTagPicked.sub_account_id,
              sub_account_name: state.subAccountTagPicked.sub_account_name,
            },
          }
        : {}),
    }

    const urlParams = new URLSearchParams(window.location.search)
    const batchId = urlParams.get('batch_id')

    if (Number(batchId)) {
      params.batch_id = batchId
      params.display_total_fees = booking.display_total_fees
      params.batch_tracking_token = BatchHelpers.genBatchTrackingToken()
      params.name = BatchHelpers.autoGenerateObjectNameWithDate(false)
    }

    if(isBookAgain()) {
      params.replicated_booking_source_attributes = {
        source_type: 'customer_duplicate', source_id:  state.bookAgainDetails.id
      }
    }

    const { bankTransfer, payment_method_for_non_bp: paymentMethodFor_nonBp } = state.booking
    if (Utils.isAddPaymentAttr(bankTransfer, paymentMethodFor_nonBp)) {
      params.payment_attributes = Utils.getPaymentAttributes(bankTransfer)
      delete params.company_id
    }
    const isNewEntry = state.extraInfos?.enable_new_booking_entry_service
    const apiInfo = {
      dataSend:isNewEntry ? params : objectToFormData(params),
      url:isNewEntry ? `${API_GATEWAY}/v1/booking-entry/bookings` : '/api/v3/bookings'
    }

    try {
      const res = await apiClient.post(apiInfo.url, apiInfo.dataSend, {
        headers: {
          'Device-Id': getDeviceId(),
          'Device-Type': 'web_single',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  share: async (state, callback) => {
    const query = {
      include: [
        'driver.vehicles',
        'locations',
        'service_type',
        'extra_requirements',
        'badges',
        'vehicle_type',
        'reimbursements',
        'fleet_partner',
      ],
      methods: ['driver.driver_image', 'locations.has_signature'],
    }
    try {
      const res = await apiClient.get(`/api/v3/bookings/${state.booking.id}/share`, { params: query })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  getCODPODPopup: async (AuthenticationToken, areaID, callback) => {
    try {
      const res = await apiClient.get('/api/v3/popups/cod_pod_popup', {
        params: {
          area_id: areaID,
        },
        headers: {
          'Device-Type': 'Web',
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  get: async (bookingID, currentCustomer, callback, query = [], params) => {
    const queryParams = { ...params, include: [...query, 'locations', 'vehicle_type', 'reimbursements'] }
    try {
      const res = await apiClient.get(`/api/v3/bookings/${bookingID}`, { prams: queryParams })
      return callback(res?.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  getBookingDetail: async (bookingID, params) => {
    try {
      const res = await apiClient.get(`/api/v3/bookings/${bookingID}`, { params })
      return res?.data
    } catch (err) {
      throw new Error(err)
    }
  },
  getBookingDetailTracking: async (bookingID, includeParams) => {
    const query = {
      include: includeParams,
    }
    try {
      const res = await apiClient.get(`/api/v3/bookings/${bookingID}/tracking_info`, { params: query })
      return res?.data
    } catch (err) {
      throw new Error(err)
    }
  },
  getTallyData: async ({ state, specificStep, isLocationChanged = true, isValidLH = false }, callback) => {
    let formData = generateRequestPayloadForCalculatingBooking(state)
    const { booking, extraInfos } = state
    let url = `${TALLY_API_URL}/bookings/calculate`
    const timeType = booking.time_type

    if (timeType === LONG_HAUL || isValidLH) {
      formData.flow = 'tally'
      formData.time_type = LONG_HAUL
      // BE want to keep using old api for long haul time type
      const isNewApi = BookingAPI.checkPricingService(extraInfos)
      url = isNewApi ? '/api/v4/pricing/calculate' : '/api/v3/bookings/calculate'
      if(isNewApi && !formData.pickup_time) {
        formData.pickup_time = moment().format('YYYY-MM-DDTHH:mm:ss.SSSSZ')
      }
      // we use the sample endpoint for LONG_HAU, but they require not to sending quote_id
      delete formData.quote_id
    }

    if (+specificStep === 1) {
      formData = pick(formData, STEP_1_PAYLOAD_FIELDS)
      const payload = payloadTallyStep1(formData, timeType)
      formData = {
        ...formData,
        locations_are_changed: isLocationChanged,
        booking_extra_requirements_attributes: [],
        ...payload,
      }
    }

    // exclude invalid fields
    if (!formData.time_type) {
      delete formData.time_type
    }

    if (!formData.quick_choice_id || +formData.quick_choice_id <= 0) {
      delete formData.quick_choice_id
    }
    try {
      const res = await apiClient.post(url, formData, {
        headers: {
          'Device-Id': getDeviceId(),
        },
      })
      if (res.data) {
        return res && callback(res.data)
      }
    } catch (err) {
      throw new Error(err)
    }
  },

  /**
   * the initial use of this function is to retrieve transit time for days of full-day change at step 2
   */
  getTallyTransitTime: async (state, callback) => {
    let formData = generateRequestPayloadForCalculatingBooking(state)
    const requiredParams = [
      'company_id',
      'vehicle_type_id',
      'time_type',
      'pickup_time',
      'full_day_selected_amount',
      'booking_extra_requirements_attributes',
      'locations_attributes',
      'round_trip_discount',
      'eta_locations_id',
    ]

    const { timeType, outOfServiceStatus, extraInfos } = state
    const isValidLH =
      get(outOfServiceStatus, 'long_haul_address_valid') && get(outOfServiceStatus, 'long_haul_pickup.is_valid')
    // BE want to keep using old api for long haul time type
    let url = `${TALLY_API_URL}/bookings/calculate_fullday_transit_time`

    if (timeType === LONG_HAUL || isValidLH) {
      formData.flow = 'tally'
      // BE want to keep using old api for long haul time type
      const isNewApi = BookingAPI.checkPricingService(extraInfos)
      url = isNewApi ? '/api/v4/pricing/calculate' : '/api/v3/bookings/calculate'
      // we use the sample endpoint for LONG_HAU, but they require not to sending quote_id
      delete formData.quote_id
    }

    formData = pick(formData, requiredParams)
    if (!formData.time_type) {
      delete formData.time_type
    }
    try {
      const res = await apiClient.post(url, formData, {
        headers: {
          'Device-Id': getDeviceId(),
        },
      })
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },

  // Update tally when duration updated in live tracking
  updateTallyBooking: async (bookingID, currentCustomer, { duration, distance }) => {
    const query = {
      duration,
      ...(distance ? { distance } : {}),
    }
    try {
      const res = await apiClient.post(
        `${TALLY_API_URL}/bookings/${bookingID}/calculate?author_type=customer&flow=update_transit_time`,
        query
      )
      return res
    } catch (err) {
      throw new Error(err)
    }
  },

  getListCancelReasonLTL: async (typeReason, extraInfos, callback) => {
    const defaultCountry = extraInfos.country_code.toLowerCase()

    const currentLanguage = language[I18n.language].currentLanguage
    try {
      const res = await apiClient.get(`${LTL_API_URL}/configuration/reasons/${typeReason}?culture=${currentLanguage}`, {
        headers: {
          countrycode: defaultCountry,
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  createBookingForLTL: async (formData, state, callback) => {
    const accessToken = window.localStorage.getItem('access_token') || ''
    const { extraInfos } = state
    const defaultCountry = extraInfos.country_code.toLowerCase()
    try {
      const res = await apiClient.post(`${LTL_API_URL}/shipment/${accessToken ? '' : 'noauth/'}major`, formData, {
        headers: {
          countrycode: defaultCountry,
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  updateBookingForLTL: async (formData, state, callback) => {
    const accessToken = window.localStorage.getItem('access_token') || ''
    const { extraInfos } = state
    const defaultCountry = extraInfos.country_code.toLowerCase()

    try {
      const res = await apiClient.put(`${LTL_API_URL}/shipment/${accessToken ? '' : 'noauth/'}major`, formData, {
        headers: {
          countrycode: defaultCountry,
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  getShipmentLTLDetail: async (shipmentId, countryCode, callback) => {
    const accessToken = window.localStorage.getItem('access_token') || ''
    const defaultCountry = countryCode.toLowerCase()
    try {
      const res = await apiClient.get(`${LTL_API_URL}/shipment/${accessToken ? '' : 'noauth/'}${shipmentId}/detail`, {
        headers: {
          countrycode: defaultCountry,
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  deleteShipmentLTLUnList: async (shipmentId, defaultCountry, callback) => {
    try {
      const res = await apiClient.delete(`${LTL_API_URL}/shipment/${shipmentId}/unlist`, {
        headers: {
          countrycode: defaultCountry,
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },

  getReimbursementInfo: async (authenticationToken, bookingId, callback) => {
    try {
      const res = await apiClient.get(`/api/v3/bookings/${bookingId}/reimbursement_info`)
      return callback(res?.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  getReimbursementInfoNew: async (bookingId) => apiClient.get(`/api/v3/bookings/${bookingId}/reimbursement_info`),
  changeStatusShipmentToDraft: async (shipmentId, defaultCountry, callback) => {
    try {
      const res = await apiClient.put(`${LTL_API_URL}/shipment/${shipmentId}/change-status-to-draft`, {
        headers: {
          countrycode: defaultCountry,
        },
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  cancelBookingLTL: async ({ reasonIds, shipmentId, isPin = false }, callback) => {
    const query = {
      reasonIds,
      isPin,
    }
    try {
      const res = await apiClient.put(`${LTL_API_URL}/shipment/${shipmentId}/cancel-booked`, query)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  downloadExcel: async (authenticationToken, bookingId, callback) => {
    try {
      const res = await apiClient.get(`/api/v3/bookings/${bookingId}/receipt`)
      return callback(res.data)
    } catch (err) {
      throw new Error(err)
    }
  },
  getDevinaInfoPTL: async (shipmentId, callback) => {
    try {
      const res = await apiClient.post(`${LTL_API_URL}/shipment/shipment/devina-info`)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getBookingPrice: async (id) =>
    apiClient.get(`/api/v3/customers/booking_cards/${id}`, {
      headers: {
        'Screen-Dpi': 'mdpi',
      },
      params: {
        include: ['payment', 'extra_requirement_locations.extra_requirement'],
      },
    }),
  getServiceTypes: async (areaId, companyId = '') => {
    try {
      const res = await apiClient.get('/api/v3/service_types', {
        params: {
          include: 'vehicle_types',
          area_id: areaId,
          company_id: companyId,
        },
      })
      if (isNull(res?.data?.object)) redirectToSelectArea()
      return res.data
    } catch (err) {
      if (err.status === 401) return redirectToSelectArea()
      throw new Error(err)
    }
  },
  getBookingInfoForEdit: async (bookingId, isBookAgain) => {
    const includeParams = [
      'driver.vehicles',
      'locations',
      'service_type',
      'extra_requirements',
      'badges',
      'vehicle_type',
      'reimbursements',
      'fleet_partner',
      'custom_reimbursements',
      'sub_account_tag',
      'batch_id',
      'exceed_allowance_time',
      'settlements',
    ]
    const query = {
      include: includeParams,
    }
    try {
      const res = await apiClient.get(`/api/v3/bookings/${bookingId}`, { params: query })
      return res
    } catch (err) {
      throw new Error(err)
    }
  },
  getBookingFromQuote: async (quoteID) => {
    try {
      const res = await apiClient.get('/api/v3/bookings/quote_detail', { params: { quote_id: quoteID } })
      return res?.data || null
    } catch (err) {
      throw new Error(err)
    }
  },
  cancelBooking: async (id, params) => apiClient.post(`/api/v3/bookings/${id}/cancel`, params),
  updateCancelReason: (id, params) => apiClient.put(`/api/v3/bookings/${id}/cancel`, params),
  favoriteBooking: async (id, saveBooking, callback, isShowToastr = false) => {
    try {
      const res = await apiClient.put(`/api/v3/bookings/${id}/favorite`, {
        id,
        marked_as_favorite: saveBooking,
      })
      callback({
        attrs: {
          marked_as_favorite: res.data.object.marked_as_favorite,
          batch_tracking_token: res.data.object.batch_tracking_token,
        },
      })
      if (isShowToastr && res) {
        Utils.showToastrMessage(
          'info',
          I18n.t(`batches.messages.booking_has_been_${res.data.object.marked_as_favorite ? 'saved' : 'unsaved'}`)
        )
      }
    } catch (err) {
      throw new Error(err)
    }
  },
  favoriteBookingDetail: async (id, saveBooking, callback) => {
    try {
      const res = await apiClient.put(`/api/v3/bookings/${id}/change_booking_favorite`, {
        id,
        marked_as_favorite: saveBooking,
      })
      callback({
        marked_as_favorite: res.data?.favorite_label,
      })
    } catch (err) {
      throw new Error(err)
    }
  },
  changeBookingFavorite: (bookingID) => apiClient.put(`/api/v3/bookings/${bookingID}/change_booking_favorite`),
  changeBookingFavoriteLCL: (bookingId, isBookmark) =>
    apiClient.patch(`${API_GATEWAY}/v1/lcl/booking/${bookingId}/bookmark`, { isBookmark }),
  retry: (params) => apiClient.post(`/api/v3/bookings/${params.id}/retry`, params),
  getTrackingInfo: (id, params) => apiClient.get(`/api/v3/bookings/${id}/tracking_info`, { params }),
  requestRecoveryBooking: async (bookingID, dataSend, callback) => {
    try {
      const res = await apiClient.put(`/api/v3/bookings/${bookingID}/request_recovery`, dataSend)
      callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  devinaBooking: async (bookingID, dataSend, callback) => {
    try {
      await apiClient.post(`/api/v3/bookings/${bookingID}/cs_finding_driver`, dataSend)
      callback()
    } catch (err) {
      throw new Error(err)
    }
  },
  confirmReimbursement: async (bookingID, dataSend, callback) => {
    try {
      const res = await apiClient.put(`/api/v3/bookings/${bookingID}/reimbursements/confirm`, dataSend)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  cancelToEditBooking: async (bookingID, dataSend, callback, isNewEntry) => {
    let res
    try {
      if(isNewEntry){
        res = await apiClient.patch(`${API_GATEWAY}/v1/booking-entry/bookings/${bookingID}/cancel_to_edit`, dataSend)
      } else {
        res = await apiClient.put(`/api/v3/bookings/${bookingID}/cancel_to_edit`, dataSend)
      }
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updateBooking: (bookingID, params, isNewEntry) => {
    if(isNewEntry){
      return apiClient.patch(`${API_GATEWAY}/v1/booking-entry/bookings/${bookingID}`, params)
    } else {
      return apiClient.put(`/api/v3/bookings/${bookingID}/update`, objectToFormData(params))
    }
  },
  checkCancleAble: async (bookingID, callback) => {
    try {
      const res = await apiClient.get(`/api/v3/bookings/${bookingID}/check_cancelable`)
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  checkCancelable: (bookingID) => apiClient.get(`/api/v3/bookings/${bookingID}/check_cancelable`),
  cancleFinePayment: async (cancelLogId, dataSend, callback) => {
    try {
      await apiClient.put(`/api/v3/booking_cancel_logs/${cancelLogId}/fine_payment`, dataSend)
      return callback()
    } catch (err) {
      throw new Error(err)
    }
  },
  getDraftBookings: async (id) => {
    try {
      const res = await apiClient.get(`/api/v3/draft_bookings/${id}/get_batch`)
      return res || null
    } catch (err) {
      throw new Error(err)
    }
  },
  editNote: async (locationId, body, callback) => {
    const res = await apiClient.put(`/api/v3/locations/${locationId}/edit_note`, body)
    callback(res)
  },
  retryDevinaLocating: (params) =>
    apiClient.post(`/api/v3/bookings/${params.bookingId}/cs_finding_driver`, { id: params.bookingId }),
  getBookingInfo: (authToken, bookingId, query) =>
    apiClient.get(`/api/v3/bookings/${bookingId}`, {
      headers: {
        'Screen-Dpi': 'mdpi',
      },
      params: query,
    }),
  editBooking: (bookingId, body) => apiClient.put(`/api/v3/bookings/${bookingId}`, body),
  getRoutes: (params, countryCode) =>
    apiClient.get(`${DELIVEREE_PLACES_SERVICE_URL}/api/v2/Routes`, {
      headers: {
        'x-use-case': 'eta',
        'x-country-code': countryCode,
        Authorization: '',
      },
      params,
    }),
  getPickupLTL: (areaID) => apiClient.post('/cargo_api/v1/bookings/pickup_window', { area_id: areaID }),
  updateBookingBusiness: (bookingId) => apiClient.post(`/api/v3/business/test_booking/${bookingId}`),
  downloadPictures: async (id, options, type) => {
    const params = { options, id }
    const res = await apiClient.get(`/api/v3/bookings/${id}/download_photos`, { params })
    if (res.data?.link) {
      $('.DetailBooking-List--DownloadGroup').removeClass('loading')
      window.open(res.data.link, '_blank').focus()
    } else {
      $('.DetailBooking-List--DownloadGroup').removeClass('loading')
      if (type === 'all') {
        $('.DetailBooking-List--DownloadGroup').addClass('all-failed')
      } else {
        $('.DetailBooking-List--DownloadGroup').addClass('select-failed')
      }
    }
  },
  addTippingBooking: (params) =>
    apiClient.post(`/api/v3/bookings/${params.bookingId}/vendor_tippings`, {
      vendor_tipping_id: params.vendor_tipping_id,
      amount: params.amount,
    }),
}
export default BookingAPI
