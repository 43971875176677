import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import $ from 'jquery'
// UTILS
import { LocationPopover } from 'utils/LocationUtil'
import locationUtils from 'utils/common/location'
import { currentVehicleType, isCustomerEditBooking } from 'utils/new_booking/common'
import roundTripDiscountUtils from 'utils/common/booking/roundTripDiscount'
import { ShoppingUtils } from 'utils/booking/ShoppingUtils'
import { Utils } from 'utils/Utils'
import { combinePhoneNumber } from 'utils/booking/LTLbooking'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
import { appsFlyerTrackEvent } from 'utils/trackingAppsFlyer'
// ACTIONS
import * as locationActionCreators from 'store/actions/new_booking/locationActionCreators'
import * as roundTripDiscountActionCreators from 'store/actions/new_booking/roundTripDiscountActionCreators'
import * as recentLocationsActionCreators from 'store/actions/common/recentLocationsActionCreators'
// API
import LocationAPI from 'api/locations'
// COMPONENTS
import OptimizeRoute from '../../common/OptimizeRoute'
import LocationCompletion from './LocationCompletion'
import CheckBoxTemplate from '../../common/round_trip/CheckBoxTemplate'
// CONSTANTS
import { DEFAULT_ACCEPTABLE_TIME_TYPES, FULL_DAY, NOW } from 'constants/bookingConstants'
import { getIndexListOfValidAddress, getOSAAndESAList, setDefaultMaxLocation, setErrorDistanceList } from 'utils/booking/common'
import { ADD_DROP_OFF_PRESS } from 'constants/trackingAppsFlyer'
// ASSETS
import ChangePickupLocation from '../../common/ChangePickupLocation'
import { withNavigate } from 'hocs/withNavigate'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { infoAssignDriverPopupActionsCreator } from 'store/toolkit/infoAssignDriverPopup/infoAssignDriverPopup.reducer'
import CommonUtils from 'utils/common'
import mapUtils from 'utils/common/map'

const mapStateToProps = state => ({
  assignedDriver: state.assignedDriver,
  booking: state.booking,
  currentCustomer: state.currentCustomer,
  extraInfos: state.extraInfos,
  googleMap: state.googleMap,
  mapService: state.mapService,
  isOptimized: state.isOptimized,
  locations: state.locations,
  timeType: state.timeType,
  timeTypeUI: state.timeTypeUI,
  roundTripDiscount: state.roundTripDiscount,
  selectedVehicleType: currentVehicleType(state),
  beforeOptimizedLocations: state.beforeOptimizedLocations,
  recentLocations: state.recentLocations,
  selectedServiceTypeID: state.selectedServiceTypeID,
  selectedVehicleTypeID: state.selectedVehicleTypeID,
  state: state.pickupTime
})

function mapDispatchToProps(dispatch) {
  return {
    locationActions: bindActionCreators(locationActionCreators, dispatch),
    roundTripDiscountActions: bindActionCreators(roundTripDiscountActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    infoAssignDriverPopupActions: bindActionCreators(infoAssignDriverPopupActionsCreator, dispatch),
    recentLocationsActions: bindActionCreators(recentLocationsActionCreators, dispatch),
  }
}

class Locations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoadedMap: false,
      outsideList: [],
      focusInputID: '',
      isShowPopup: false,
      queryStringUrl: '',
      isErrorInput: false,
    }
    this.handleOptimize = this.handleOptimize.bind(this)
    this.handleRoundTrip = this.handleRoundTrip.bind(this)
    this.handleLocationUpdate = this.handleLocationUpdate.bind(this)
    this.removeIndexOutsideList = this.removeIndexOutsideList.bind(this)
    this.removeIndexErrorDistanceList = this.removeIndexErrorDistanceList.bind(this)
    this.setOutsideList = this.setOutsideList.bind(this)
    this.setFocusInputID = this.setFocusInputID.bind(this)
    this.isLoadLTLLocation = true
    this.handleClosePopup = this.handleClosePopup.bind(this)
    this.handleYesConfirm = this.handleYesConfirm.bind(this)
    this.handleRemoveErrorInput = this.handleRemoveErrorInput.bind(this)
  }

  componentDidMount() {
    const {
      locationActions, 
      isShopping: updateBookerForLocations,
      currentCustomer, recentLocations, recentLocationsActions
    } = this.props
    locationActions.setUpLocations(updateBookerForLocations)
    Utils.detectDropdownActionsLocation()

    if(currentCustomer.id && !recentLocations?.length) {
      recentLocationsActions.loadRecentLocations()
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      isLoadedMap, outsideList, redirect
    } = this.state
    const {
      timeType, locations, locationActions, isShopping, setListErrorDistance,
      currentStep, selectedServiceTypeID, selectedVehicleTypeID, booking,
      isHaveLHAddress: curIsHaveLHAddress, fullLoadView, extraInfos
    } = this.props
    const {
      locations: nextLocations, outsideList: nextOutsideList,
      booking: nextBooking, isHaveLHAddress, roundTripDiscount, isEnableGoogleMap
    } = nextProps
    const otherChange = nextProps.timeType !== timeType
      || selectedServiceTypeID !== nextProps.selectedServiceTypeID
      || selectedVehicleTypeID !== nextProps.selectedVehicleTypeID
    // Remove Return discount checkbox start
    if (curIsHaveLHAddress !== isHaveLHAddress && roundTripDiscount) {
      this.handleRoundTrip(false)
    }
    // Remove reutrn discount checkbox end
    if (nextLocations.length > 2) {
      this.initSortable()
    } else {
      $(this.sortableList).sortable().sortable('destroy')
    }
    if (isShopping !== nextProps.isShopping && nextProps.isShopping === true) {
      this.handleActionUpdateLocation()
    }
    if (nextProps.timeType !== timeType || (fullLoadView !== nextProps.fullLoadView && !fullLoadView)) {
      if (nextProps.timeType !== FULL_DAY && _.size(locations) === 1) {
        locationActions.addLocation()
      } else if (nextProps.timeType === FULL_DAY) {
        _.forEach(locations, (location, index) => {
          if (index !== 0 && _.isUndefined(location.lat)) {
            this.removeLocationNoData(location, false)
          }
        })
      }
    }
    if (booking.routes !== nextBooking.routes && currentStep === 1) {
      const errorDistanceList = setErrorDistanceList(nextBooking.routes, nextLocations)
      setListErrorDistance(errorDistanceList)
    }
    this.handleChangeLocation(nextProps, otherChange)
    if (otherChange) {
      this.handleCloseTooltip()
    }

    const googleMapNew = nextProps.googleMap
    const mapServiceNew = nextProps.mapService
    if(((isEnableGoogleMap && googleMapNew.map) || mapServiceNew) && !_.isEmpty(nextBooking.shipmentLTLDetail) && this.isLoadLTLLocation) {
      this.setShipmentLTLDetail(nextBooking, googleMapNew, mapServiceNew, isEnableGoogleMap)
    }

    if (JSON.stringify(booking.routes) !== JSON.stringify(nextBooking.routes) && !redirect && currentStep === 1) {
      const errorDistanceList = setErrorDistanceList(nextBooking.routes, nextLocations)
      setListErrorDistance(errorDistanceList)
    }
    if (JSON.stringify(outsideList) !== JSON.stringify(nextOutsideList) && !redirect) {
      this.setOutsideList(nextOutsideList)
    }

    if (!isLoadedMap && ((isEnableGoogleMap && googleMapNew.map) || mapServiceNew)) {
      const urlParams = new URLSearchParams(window.location.search)
      const isPtl = urlParams.get('ptl')
      const isSwitchArea = urlParams.get('is_switch_area')
      this.setCenterFromToAndFillDataAddress(urlParams, mapServiceNew, googleMapNew, isEnableGoogleMap)
      // FOR LOCATION FROM URL
      const queryStringSwitchArea = localStorage.getItem('query_string_switch_area') || ''
      const queryStringLocation = window.location.search
      const queryStringFinal = (isSwitchArea && queryStringSwitchArea) || queryStringLocation
      if (queryStringFinal && !isPtl) {
        localStorage.removeItem('query_string_switch_area')
        const urlFromFrontPage = new URL(window.location.origin)
        urlFromFrontPage.searchParams.set("frontPage", "false")

        if (isSwitchArea && queryStringSwitchArea) {
          window.history.pushState(
            {},
            null,
            extraInfos.turn_on_front_page ? urlFromFrontPage : window.location.origin
          )
        }
        const locationsParams = decodeURIComponent(queryStringFinal).split(';').filter(x => x.includes('location'))
        
        let taskIndex = 0

        const updateLocationFromUrl = new Promise((done) => {
          const getNextTask = () => {
            if (taskIndex < locationsParams.length) {
              return Promise.resolve(locationsParams[taskIndex]).then(
                (location) => {
                  const parsedData = location.split('&').filter(x => x.includes('location'))
                  if (parsedData.length === 4) {
                    const locationIndex = Number(parsedData[0].split('][')[0].replace('location[', ''))
                    const name = parsedData[0].split('=')[1]
                    const lat = parsedData[1].split('=')[1]
                    const lng = parsedData[2].split('=')[1]
                    const addressCompos = JSON.parse(parsedData[3].split('=')[1])
                    let marker
                    if(isEnableGoogleMap) {
                      marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(lat, lng),
                        map: googleMapNew.map,
                        animation: window.google.maps.Animation.DROP,
                        draggable: true,
                      })
                    } else {
                      marker = mapUtils.setMarker(mapServiceNew, lng, lat)
                    }
                    const locationAttrs = {
                      lat,
                      lng,
                      name,
                      marker,
                      address_components: addressCompos,
                    }

                    if (locations[locationIndex]) {
                      locationActions.actionUpdateLocation(
                        locations[locationIndex].id,
                        locationAttrs,
                        undefined,
                        false
                      )
                    }

                    if (locationIndex > locations.length - 1) {
                      // ADD LOCATION
                      locationAttrs.id = Utils.uniqueId()
                      locationActions.addLocation(locationAttrs)
                    }

                    const locationId = locations[locationIndex] ? locations[locationIndex].id : locationAttrs.id

                    this.addEventListenerOnMarker(locationId, marker)
                    setTimeout(() => {
                      taskIndex += 1
                      locationActions.drawBookingLocationRoute()
                      getNextTask()
                    }, 100)
                  } else {
                    const address = parsedData[0].split('=')[1]
                    const locationIndex = Number(parsedData[0].split('][')[0]).replace('location[', '')

                    locationUtils.handleCallGeocodeAPI({
                      address,
                    }, (results) => {
                      if (results) {
                        const name = address
                        const { addressComponent, lat, lng } = locationUtils.getDataResponse(results[0])
                        let marker
                        if(isEnableGoogleMap) {
                          const latLng = new window.google.maps.LatLng(lat, lng)
                          marker = new window.google.maps.Marker({
                            position: latLng,
                            map: googleMapNew.map,
                            animation: window.google.maps.Animation.DROP,
                            draggable: true,
                          })
                        } else {
                          marker = mapUtils.setMarker(mapServiceNew, lng, lat)
                        }

                        const locationAttrs = {
                          lat,
                          lng,
                          name,
                          marker,
                          address_components: addressComponent,
                        }

                        if (locations[locationIndex]) {
                          locationActions.actionUpdateLocation(
                            locations[locationIndex].id,
                            locationAttrs,
                            undefined,
                            false
                          )
                        }

                        if (locationIndex > locations.length - 1) {
                          // ADD LOCATION
                          locationAttrs.id = Utils.uniqueId()
                          locationActions.addLocation(locationAttrs)
                        }
                        const locationId = locations[locationIndex] ? locations[locationIndex].id : locationAttrs.id

                        mapUtils.handleFitBounds({
                          mapService: mapServiceNew,
                          googleMap: googleMapNew,
                          isEnableGoogleMap,
                          coordinates: [{ lng, lat }]
                        })

                        this.addEventListenerOnMarker(locationId, marker)
                        setTimeout(() => {
                          taskIndex += 1
                          locationActions.drawBookingLocationRoute()
                          getNextTask()
                        }, 100)
                      }
                    }, extraInfos)
                  }
                }
              ).catch(
                () => {
                  taskIndex += 1
                  getNextTask()
                }
              )
            }
            return done(locationsParams)
          }
          getNextTask()
        })

        if (locationsParams.length > 0) {
          if (!timeType && !$('.PickupTime').hasClass('error')) {
            $('.PickupTime').addClass('error')
          }
          updateLocationFromUrl.then(() => {
            locationActions.drawBookingLocationRoute()
          })
        }
      }

      this.setState({ isLoadedMap: true })
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleChangeLocation(nextProps, otherChange) {
    const {
      locations
    } = this.props
    const {
      locations: nextLocations, outsideList: nextOutsideList,
      area, booking: nextBooking,
    } = nextProps
    const validLocation = _.filter(locations, item => (
      item.name && item.lat && item.lng))
    const validNextLocation = _.filter(nextLocations, item => (
      item.name && item.lat && item.lng))
    const isChangedFirstLocation = locations?.length
      && locationUtils.isChangeLocations([locations[0]], [nextLocations[0]])
    const isChangedLocation = locationUtils.isChangeLocations(validLocation, validNextLocation)
    if (isChangedFirstLocation) {
      this.checkLocationInArea(
        validNextLocation, nextLocations, nextOutsideList, nextProps, nextBooking, area
      )
    }
    const isDeleteSecondAddress = validNextLocation.length === 1
        && validLocation.length === 2 && !otherChange && nextProps.timeType !== FULL_DAY
    if (isDeleteSecondAddress && isChangedLocation) {
      this.validateALocations(nextLocations, nextProps.timeType)
    }
    if (((!isChangedFirstLocation && isChangedLocation) || otherChange) && validNextLocation.length === 1) {
      this.validateALocations(nextLocations, nextProps.timeType)
    }
  }

  setShipmentLTLDetail(booking, googleMapNew, mapServiceNew, isEnableGoogleMap) {
    const {
      locationActions,
      locations,
    } = this.props
    let taskIndex = 0
    const updateShipmentLTLDetail = new Promise((done) => {
      const getNextTask = () => {
        if (taskIndex < locations.length) {
          return Promise.resolve(locations[taskIndex]).then(
            () => {
              const shipmentPickup = _.get(booking, 'shipmentLTLDetail.addresses.pickup')
              const shipmentDes = _.get(booking, 'shipmentLTLDetail.addresses.destinations[0]')
              const shipmentAddress = taskIndex === 0 ? shipmentPickup : shipmentDes

              if (!_.isEmpty(shipmentAddress)) {
                const {
                  address, contactName, contactPhone, contactDialCode, jsonAddress, note
                } = shipmentAddress
                const lat = _.get(shipmentAddress, 'location.latitude')
                const lng = _.get(shipmentAddress, 'location.longitude')
                const recipientPhone = contactPhone ? combinePhoneNumber(contactPhone, contactDialCode) : ''
                const locationAttrs = {
                  recipient_name: contactName,
                  recipient_phone: recipientPhone,
                  description: note,
                }

                if (lat && lng) {
                  let marker
                  if(isEnableGoogleMap) {
                    const latLng = new window.google.maps.LatLng(lat, lng)
                    marker = new window.google.maps.Marker({
                      position: latLng,
                      map: googleMapNew.map,
                      animation: window.google.maps.Animation.DROP,
                      draggable: true,
                    })
                  } else {
                    marker = mapUtils.setMarker(mapServiceNew, lng, lat)
                  }
                  locationAttrs.lat = lat
                  locationAttrs.lng = lng
                  locationAttrs.name = address
                  locationAttrs.marker = marker
                  locationAttrs.address_components = jsonAddress ? JSON.parse(jsonAddress) : []
                  this.addEventListenerOnMarker(locations[taskIndex].id, marker)
                }

                if (locations[taskIndex]) {
                  locationActions.actionUpdateLocation(
                    locations[taskIndex].id,
                    locationAttrs,
                    undefined,
                    false
                  )
                }
                setTimeout(() => {
                  taskIndex += 1
                  locationActions.drawBookingLocationRoute()
                  getNextTask()
                }, 100)
              }
            }
          ).catch(
            (error) => {
              console.log(error)
              taskIndex += 1
              getNextTask()
            }
          )
        }
        return done(locations)
      }
      getNextTask()
    })

    updateShipmentLTLDetail.then(() => { locationActions.drawBookingLocationRoute() })
    this.isLoadLTLLocation = false
  }

  setCenterFromToAndFillDataAddress(urlParams, mapServiceNew, googleMapNew, isEnableGoogleMap) {
    const { locationActions, locations } = this.props
    const isPtl = urlParams.get('ptl')
    const lat1 = urlParams.get('mk_booking[locations][][latitude1]')
    const lng1 = urlParams.get('mk_booking[locations][][longitude1]')
    const name1 = urlParams.get('mk_booking[locations][][name1]')
    const lat2 = urlParams.get('mk_booking[locations][][latitude2]')
    const lng2 = urlParams.get('mk_booking[locations][][longitude2]')
    const name2 = urlParams.get('mk_booking[locations][][name2]')
    if (isPtl === 'true' && lat1 && lng1 && lat2 && lng2 && name1 && name2) {
      let taskIndex = 0
      const updateLocationFromUrl = new Promise((done) => {
        const getNextTask = () => {
          if (taskIndex < locations.length) {
            return Promise.resolve(locations[taskIndex]).then(
              () => {
                if (taskIndex === 0) {
                  const name = name1
                  const lat = lat1
                  const lng = lng1
                  let marker
                  if (isEnableGoogleMap) {
                    marker = new window.google.maps.Marker({
                      position: new window.google.maps.LatLng(lat, lng),
                      map: googleMapNew.map,
                      animation: window.google.maps.Animation.DROP,
                      draggable: true,
                    })
                  } else {
                    marker = mapUtils.setMarker(mapServiceNew, lng, lat)
                  }

                  const locationAttrs = {
                    lat,
                    lng,
                    name,
                    marker,
                  }

                  if (locations[taskIndex]) {
                    locationActions.actionUpdateLocation(
                      locations[taskIndex].id,
                      locationAttrs,
                      undefined,
                      false
                    )
                  }

                  this.addEventListenerOnMarker(locations[taskIndex].id, marker)
                  setTimeout(() => {
                    taskIndex += 1
                    locationActions.drawBookingLocationRoute()
                    getNextTask()
                  }, 100)
                } else {
                  const lat = lat2
                  const lng = lng2
                  const name = name2
                  let marker
                  if(isEnableGoogleMap) {
                    const latLng = new window.google.maps.LatLng(lat, lng)
                    marker = new window.google.maps.Marker({
                      position: latLng,
                      map: googleMapNew.map,
                      animation: window.google.maps.Animation.DROP,
                      draggable: true,
                    })
                  } else {
                    marker = mapUtils.setMarker(mapServiceNew, lng, lat)
                  }

                  const locationAttrs = {
                    lat,
                    lng,
                    name,
                    marker,
                  }

                  if (locations[taskIndex]) {
                    locationActions.actionUpdateLocation(
                      locations[taskIndex].id,
                      locationAttrs,
                      undefined,
                      false
                    )
                  }

                  mapUtils.handleFitBounds({
                    mapService: mapServiceNew,
                    googleMap: googleMapNew,
                    isEnableGoogleMap,
                    coordinates: [{ lng, lat }]
                  })

                  this.addEventListenerOnMarker(locations[taskIndex].id, marker)
                  setTimeout(() => {
                    taskIndex += 1
                    locationActions.drawBookingLocationRoute()
                    getNextTask()
                  }, 100)
                }
              }
            ).catch(
              () => {
                taskIndex += 1
                getNextTask()
              }
            )
          }
          return done(locations)
        }
        getNextTask()
      })
      updateLocationFromUrl.then(() => { locationActions.drawBookingLocationRoute() })
    }
  }

  /**
   * Set value for focusInputID state
   * @param {String} focusInputID
   */
  setFocusInputID(focusInputID) {
    this.setState({
      focusInputID,
    })
  }

  /**
   * Set value for outsideList state
   * @param {Array} outsideList
   */
  setOutsideList(outsideList) {
    const { setOutsideList } = this.props
    this.setState({
      outsideList,
    }, () => {
      setOutsideList(outsideList)
    })
  }

  checkLocationInArea(
    validAddressList, nextLocations, nextOutsideList, nextProps, nextBooking, area 
  ) {
    const {
      setListErrorDistance, outsideList, booking, currentStep, fullLoadView, currentCustomer, isEnableGoogleMap
    } = this.props
    const authToken = window.localStorage.getItem('access_token') || ''
    const companyId = currentCustomer.current_company_id || 0
    LocationAPI.validLocationsInArea(
      nextLocations,
      area.id,
      (result) => {
        if (!_.isEmpty(result)) {
          if (result.correct_area && result.status === 422 && !isCustomerEditBooking()) {
            const validLocations = _.filter(nextLocations, location => (location.lat && location.lng))
            const queryStringSwitchArea = `/?area_id=${result.correct_area}&is_switch_area=true`
            let queryString = queryStringSwitchArea
            validLocations.forEach((location, index) => {
              queryString += `&location[${index}][name]=${encodeURI(location.name)}&location[${index}][lat]=${encodeURI(location.lat)}&location[${index}][lng]=${encodeURI(location.lng)}&location[${index}][address_components]=${encodeURIComponent(JSON.stringify(location.address_components))};`
            })
            const urlParams = new URLSearchParams(window.location.search)
            const batchId = urlParams.get('batch_id')

            if (Number(batchId)) {
              this.setState({ isShowPopup: true, queryStringUrl: queryString, isErrorInput: true })
            } else {
              localStorage.setItem('query_string_switch_area', queryString)
              CommonUtils.goToNewLocation(queryStringSwitchArea)
              // navigate(queryStringSwitchArea)
            }
          } else {
            if (validAddressList.length === 1 && fullLoadView) {
              this.validateALocations(nextLocations, nextProps.timeType)
            }
            if (JSON.stringify(booking.routes) !== JSON.stringify(nextBooking.routes) && currentStep === 1) {
              const errorDistanceList = setErrorDistanceList(nextBooking.routes, nextLocations)
              setListErrorDistance(errorDistanceList)
            }
            if (JSON.stringify(outsideList) !== JSON.stringify(nextOutsideList)) {
              this.setOutsideList(nextOutsideList)
            }
          }
        }
      }, authToken, companyId, isEnableGoogleMap
    )
  }

  /**
   * Close tooltip
   */
  handleCloseTooltip() {
    const btnClose = document.getElementsByClassName('Popover custom visible')
    if (btnClose && btnClose[0]) {
      const btnClassName = btnClose[0].className
      btnClose[0].className = _.replace(btnClassName, ' visible', '')
    }
    return this
  }

  /**
   * Remove index from outsideList
   * @param {String} id
   */
  removeIndexOutsideList(id) {
    const { outsideList } = this.state
    const newList = []
    _.map(outsideList, (item) => {
      if (item !== id) {
        newList.push(item)
      }
    })
    this.setOutsideList(newList)
  }

  removeIndexErrorDistanceList() {
    const { setListErrorDistance } = this.props
    setListErrorDistance([])
  }

  validateALocations(locations, timeType) {
    const {
      selectedServiceTypeID,
      selectedVehicleTypeID,
      currentCustomer,
      fullLoadView,
      area,
      isEnableGoogleMap,
      pickupTime
    } = this.props

    const isSimpleData = true
    LocationAPI.getCheckLocations(
      locations,
      {
        timeType,
        serviceTypeID: selectedServiceTypeID,
        vehicleTypeID: selectedVehicleTypeID,
        isSimpleData,
        pickupTime
      },
      {
        countryCode: area.country_code,
        companyId: currentCustomer.current_company_id,
        isEnableGoogleMap
      },
      null,
      (resultApi) => {
        if (resultApi.status !== 400) {
          const indexList = getIndexListOfValidAddress(locations)
          const { outside_list: outsideList, extended_list: extendedList } = resultApi
          const { formatOutSideList, normalOSAList } = getOSAAndESAList(locations, indexList, outsideList, extendedList)
          let finalOSAList = formatOutSideList
          if (timeType !== NOW) {
            finalOSAList = normalOSAList
          }
          if (_.includes(DEFAULT_ACCEPTABLE_TIME_TYPES, timeType) || !fullLoadView) {
            this.setOutsideList(finalOSAList)
          }
        }
      },
      fullLoadView,
    )
  }

  addEventListenerOnMarker(locationId, marker) {
    const { locationActions, isEnableGoogleMap, extraInfos } = this.props
    window.google.maps.event.addListener(marker, 'dragend', () => {
      const latlng = locationUtils.latlngGenerate(marker, isEnableGoogleMap)
      locationUtils.handleCallGeocodeAPI({ latlng }, (results) => {
        if (results) {
          const { addressComponent, formattedAddress } = locationUtils.getDataResponse(results[0])
          const { lat, lng } = mapUtils.getLatLngFromMarker(marker, isEnableGoogleMap)
          const attrs = {
            lat: lat,
            lng: lng,
            name: formattedAddress,
            address_components: addressComponent,
            marker,
          }
          locationActions.actionUpdateLocation(locationId, attrs, undefined, false)
          locationActions.updateOptimizeState(false)
        }
      }, extraInfos)
    });

    window.google.maps.event.addListener(marker, 'click', () => {
      this.handleZoomMarker(locationId)
    })
  }

  handleZoomMarker(locationId) {
    const { locations, extraInfos, mapService, isEnableGoogleMap, googleMap } = this.props
    const currentLocation = locations.find(x => x.id === locationId.toString())
    if (currentLocation.lat && currentLocation.lng && currentLocation.name) {
      if(isEnableGoogleMap) {
        googleMap.map.setZoom(extraInfos.default_pickup_zoom)
        googleMap.map.setCenter(new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng))
      } else {
        mapService.setZoom(extraInfos.default_pickup_zoom)
        mapService.setCenter([currentLocation.lng, currentLocation.lat])
      }
    }
  }

  handleActionUpdateLocation() {
    const {
      locations, currentCustomer, locationActions, isEnableGoogleMap
    } = this.props
    _.forEach(locations, (location, index) => {
      if (index > 1) {
        this.removeLocationNoData(location, true)
      } else {
        const locationAttrs = {
          lat: undefined,
          lng: undefined,
          name: undefined,
          marker: undefined,
          contact_id: undefined,
          recipient_name: currentCustomer.name,
          recipient_phone: currentCustomer.phone,
        }
        if (location.marker) {
          if (isEnableGoogleMap) {
            location.marker.setMap(null)
            window.google.maps.event.clearListeners(location.marker, 'dragend')
          } else {
            location.marker.remove();
          }
        }
        locationActions.actionUpdateLocation(location.id, locationAttrs, undefined, false)
        locationActions.locationActions(false)
        LocationPopover.resetInput(location.id)
      }
    })
  }

  initSortable() {
    const { assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions } = this.props
    $(this.sortableList).sortable({
      revert: true,
      axis: 'y',
      // cancel: 'textarea:not(.sortable-enable), input:not(.sortable-enable)',
      cursor: 'move',
      start: (_e, ui) => {
        $(ui.item).addClass('location-dragging')
      },
      stop: (event, ui) => {
        $(ui.item).removeClass('location-dragging')
        const { locations } = this.props
        const sortedIDs = _.map($(ReactDOM.findDOMNode(this.refs.list)).find('[id^=\'location-completion-\']'), dom => $(dom).attr('id').split('-')[2])
        const locationIDs = _.map(locations, 'id')
        if (assignedDriver && !_.isEqual(sortedIDs, locationIDs)) {
          Utils.updateInfoAssignDriverPopupActions(
            {
              func: this.dragChangeLocationAfterCheckBookingDetail.bind(this, sortedIDs),
              backFunc: this.backButtonAssignDriver.bind(this)
            },
            currentPopupIDActions,
            infoAssignDriverPopupActions
          )
        } else {
          this.dragChangeLocationAfterCheckBookingDetail(sortedIDs)
        }
      }
    }).disableSelection()
  }


  backButtonAssignDriver() {
    $(this.sortableList).sortable('cancel')
  }

  dragChangeLocationAfterCheckBookingDetail(sortedIDs) {
    const {
      locations,
      locationActions,
      extraInfos,
      booking,
      timeType,
      fullLoadView
    } = this.props
    const sortedLocations = []
    const isFulldayMegazone = extraInfos.enable_full_day_megazone && timeType === FULL_DAY && fullLoadView
    if (!_.isEqual(sortedIDs, _.map(locations, 'id'))) {
      _.each(sortedIDs, (locationID) => {
        const location = _.find(locations, loc => _.toInteger(loc.id) === _.toInteger(locationID))
        sortedLocations.push(location)
      })
      if (extraInfos.default_payer_is_destination === true) {
        _.assign(_.find(sortedLocations, { is_payer: true }), { is_payer: false })
        _.assign(sortedLocations[sortedLocations.length - 1], { is_payer: true })
      }
      locationActions.updateOptimizeState(false)
      Promise.resolve(
        locationActions.setLocations(sortedLocations)
      ).then(() => {
        locationActions.drawBookingLocationRoute('', isFulldayMegazone && booking.outsideList)
      })
    }
  }

  removeLocationNoData(location, toggleRemove = false) {
    const { locationActions } = this.props
    if (!location.marker || toggleRemove) {
      locationActions.removeLocation(location)
      locationActions.updateOptimizeState(false)
    }
  }

  addLocation() {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions, currentCustomer
    } = this.props
    window.Moengage.track_event('Add Drop Selected')
    appsFlyerTrackEvent(ADD_DROP_OFF_PRESS, { customer_id: currentCustomer.id })
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        {
          func: this.addLocationAfterCheckBookingDetails.bind(this)
        },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.addLocationAfterCheckBookingDetails()
    }
    this.handleCloseTooltip()
  }

  addLocationAfterCheckBookingDetails() {
    const { locationActions, locations } = this.props
    Promise.resolve(
      locationActions.addLocation()
    ).then(() => {
      locationActions.updateOptimizeState(false)
      $('.BookingWizard:not(".Custom-Step") .Content').animate({
        scrollTop: $('.BookingWizard:not(".Custom-Step") .Content').prop('scrollHeight')
      }, 1000)
    }).then(() => {
      const config = {
        case: 'new-booking',
        bookingID: locations.length,
        timeout: 400
      }
      LocationPopover.toggleContactPopovers(config)
      locationActions.drawBookingLocationRoute()
    })
  }

  canAddLocation() {
    const { locations, extraInfos, currentCustomer } = this.props
    return locations.length < setDefaultMaxLocation(currentCustomer, extraInfos)
  }

  handleOptimize(checked) {
    const {
      assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions, errorDistanceList, locationActions
    } = this.props
    if (!_.isEmpty(errorDistanceList)) {
      locationActions.updateOptimizeState(checked)
    } else if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.handleOptimizeAfterCheckBookingDetail.bind(this, checked) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.handleOptimizeAfterCheckBookingDetail(checked)
    }
    this.handleCloseTooltip()
  }

  handleOptimizeAfterCheckBookingDetail(checked) {
    toastr.remove()
    toastr.options = { positionClass: 'toast-top-center new-booking' }
    const {
      locations,
      timeType,
      locationActions,
      booking,
      extraInfos,
      fullLoadView
    } = this.props

    const locationsTemp = locationUtils.filterEmptyLocations(locations)
    const isFulldayMegazone = extraInfos.enable_full_day_megazone && timeType === FULL_DAY && fullLoadView
    if (LocationPopover.isValidOptimizeLocations(locationsTemp)) {
      if (checked) {
        Promise.resolve(
          locationActions.optimizeLocations(locationsTemp, isFulldayMegazone && booking.outsideList)
        ).then(() => {
          toastr.info(I18n.t('success.booking.route_is_optimized'))
        })
      } else {
        locationActions.resetOptimizeLocations(isFulldayMegazone && booking.outsideList)
      }
    } else {
      const invalidIndexes = locationUtils.invalidLocationIndexes(timeType, locationsTemp)
      const locationElems = $('.Locations.Locations-Customs').find('.block-item .Input')
      invalidIndexes.forEach((index) => {
        $(locationElems[index]).addClass('error')
      })
      if (invalidIndexes.length > 0) {
        return false
      }
    }
  }

  showRoundTripDiscountCheckBox() {
    const {
      currentCustomer, locations, timeTypeUI, roundTripDiscount,
      selectedVehicleType, extraInfos, isHaveLHAddress,
    } = this.props
    const enableRoundTrip = _.isUndefined(selectedVehicleType)
      ? []
      : selectedVehicleType.settings.enable_round_trip
    return !isHaveLHAddress && roundTripDiscountUtils.shouldShow(
      locations,
      roundTripDiscount,
      timeTypeUI,
      currentCustomer.current_company_id,
      enableRoundTrip,
      setDefaultMaxLocation(currentCustomer, extraInfos),
      extraInfos,
    )
  }

  handleRoundTrip(checked) {
    const { assignedDriver, currentPopupIDActions, infoAssignDriverPopupActions } = this.props
    if (assignedDriver) {
      Utils.updateInfoAssignDriverPopupActions(
        { func: this.handleRoundTripAfterCheckBookingDetail.bind(this, checked) },
        currentPopupIDActions,
        infoAssignDriverPopupActions
      )
    } else {
      this.handleRoundTripAfterCheckBookingDetail(checked)
    }
    this.handleCloseTooltip()
  }

  handleRoundTripAfterCheckBookingDetail(checked) {
    const { roundTripDiscountActions } = this.props
    Promise.resolve(
      roundTripDiscountActions.update(checked)
    ).then(() => {
      $('.BookingWizard:not(".Custom-Step") .Content').animate({
        scrollTop: $('.BookingWizard:not(".Custom-Step") .Content').prop('scrollHeight')
      }, 1000)
      if (checked) {
        window.Moengage.track_event('Return Trip Discount Selected')
      }
    })
  }

  handleLocationUpdate(location, locationAtr, isRecentLocations = false) {
    const {
      locationActions,
      recentLocationsActions,
      currentCustomer,
      recentLocations,
      mapService,
      location: oldLocation,
      googleMap,
      isEnableGoogleMap,
      extraInfos
    } = this.props
    locationActions.actionUpdateLocation(location.id, { ...locationAtr }, undefined, false)
    if (!isRecentLocations && location.lat && location.lng && location.name) {
      recentLocationsActions.setRecentLocation(currentCustomer, location)
    }
    if (isRecentLocations) {
      const recentL = _.find(recentLocations, r => r && r.name === locationAtr.name)
      if (!_.isEmpty(recentL) && _.isEmpty(recentL.address_components)) {
        locationUtils.handleCallGeocodeAPI({ address: recentL.name }, (results) => {
          if (results) {
            const {
              addressComponent, formattedAddress, lat, lng
            } = locationUtils.getDataResponse(results[0])
            let marker
            mapUtils.removeMarker(oldLocation?.marker, isEnableGoogleMap)

            if (isEnableGoogleMap) {
              const latLng = new window.google.maps.LatLng(lat, lng)
              marker = new window.google.maps.Marker({
                position: latLng,
                map: googleMap.map,
                animation: window.google.maps.Animation.DROP,
                draggable: true,
              })
            } else {
              marker = mapUtils.setMarker(mapService, lng, lat)
            }
            const attrs = {
              lat,
              lng,
              name: formattedAddress,
              address_components: addressComponent,
              marker,
            }
            locationActions.actionUpdateLocation(location.id, attrs, undefined, false)
            // update address component for recent location
            recentLocationsActions.updateAddressComponentRecentLocation(currentCustomer, {
              ...recentL,
              address_components: _.clone(addressComponent, true)
            })
          }
        }, extraInfos)
      }
    }
  }

  handleRemoveErrorInput() {
    this.setState({ isErrorInput: false })
  }

  handleClosePopup() {
    this.setState({ isShowPopup: false })
    const { handleShowChangePickupLocation, handleErrorPickupLocation } = this.props
    handleShowChangePickupLocation(false)
    handleErrorPickupLocation(true)
  }

  handleYesConfirm() {
    const { queryStringUrl } = this.state
    CommonUtils.goToNewLocation(queryStringUrl)
  }

  renderPopupChangePickupLocation() {
    const { isShowPopupChangePickupLocation } = this.props
    const { isShowPopup } = this.state
    if (isShowPopup || isShowPopupChangePickupLocation) {
      return (
        <ChangePickupLocation
          handleClosePopup={this.handleClosePopup}
          handleYesConfirm={this.handleYesConfirm}
        />
      )
    }
    return null
  }

  renderLocationsCompletions() {
    const {
      area, locations, roundTripDiscount, isShopping, selectedVehicleType, fullLoadView,
      setListErrorDistance, errorDistanceList, handleErrorPickupLocation, isEnableGoogleMap
    } = this.props
    const shouldShowRoundTripDiscount = this.showRoundTripDiscountCheckBox()
    const { outsideList, focusInputID, isErrorInput } = this.state
    return (
      <div ref="list">
        <LocationCompletion
          key={locations[0].id}
          index={0}
          location={locations[0]}
          area={area}
          isFirstLocation
          isDisabledContact={isShopping}
          handleLocationUpdate={this.handleLocationUpdate}
          shouldShowRoundTripDiscount={shouldShowRoundTripDiscount}
          isDisabledInputShopping={isShopping ? ShoppingUtils.verifyTimeShopping(selectedVehicleType) : false}
          fullLoadView={fullLoadView}
          outsideList={outsideList}
          removeIndexOutsideList={this.removeIndexOutsideList}
          removeIndexErrorDistanceList={this.removeIndexErrorDistanceList}
          setOutsideList={this.setOutsideList}
          errorDistanceList={errorDistanceList}
          setListErrorDistance={setListErrorDistance}
          setFocusInputID={this.setFocusInputID}
          focusInputID={focusInputID}
          isErrorInput={isErrorInput}
          handleRemoveErrorInput={this.handleRemoveErrorInput}
          handleErrorPickupLocation={handleErrorPickupLocation}
          isEnableGoogleMap={isEnableGoogleMap}
        />
        <div
          ref={(ref) => { this.sortableList = ref }}
          className="Locations-Customs-SortableList"
        >
          {locations.map((location, index) => (
            (index !== 0 && !(roundTripDiscount && index === _.size(locations) - 1))
            && (
              <LocationCompletion
                key={location.id}
                index={index}
                location={location}
                area={area}
                isFirstLocation={false}
                isDisabledContact={isShopping}
                handleLocationUpdate={this.handleLocationUpdate}
                shouldShowRoundTripDiscount={shouldShowRoundTripDiscount}
                isDisabledInputShopping={isShopping ? ShoppingUtils.verifyTimeShopping(selectedVehicleType) : false}
                fullLoadView={fullLoadView}
                outsideList={outsideList}
                removeIndexOutsideList={this.removeIndexOutsideList}
                removeIndexErrorDistanceList={this.removeIndexErrorDistanceList}
                setOutsideList={this.setOutsideList}
                errorDistanceList={errorDistanceList}
                setListErrorDistance={setListErrorDistance}
                setFocusInputID={this.setFocusInputID}
                focusInputID={focusInputID}
                isEnableGoogleMap={isEnableGoogleMap}
              />
            )
          ))}
        </div>
        {roundTripDiscount
          && (
            <LocationCompletion
              key={_.last(locations).id}
              index={_.size(locations) - 1}
              location={_.last(locations)}
              area={area}
              isFirstLocation={false}
              isDisabledContact={isShopping}
              handleLocationUpdate={this.handleLocationUpdate}
              shouldShowRoundTripDiscount={shouldShowRoundTripDiscount}
              isDisabledInputShopping={isShopping ? ShoppingUtils.verifyTimeShopping(selectedVehicleType) : false}
              fullLoadView={fullLoadView}
              outsideList={outsideList}
              removeIndexOutsideList={this.removeIndexOutsideList}
              removeIndexErrorDistanceList={this.removeIndexErrorDistanceList}
              setOutsideList={this.setOutsideList}
              errorDistanceList={errorDistanceList}
              setListErrorDistance={setListErrorDistance}
              setFocusInputID={this.setFocusInputID}
              focusInputID={focusInputID}
              isEnableGoogleMap={isEnableGoogleMap}
            />
          )
        }
      </div>
    )
  }

  renderLocationsActions() {
    const {
      booking,
      locations,
      isOptimized,
      selectedVehicleType,
      roundTripDiscount,
      errorDistanceList
    } = this.props
    const { outsideList } = this.state
    return (
      <div className="block-item flex-start-algin-important actions">
        <div className="flex-index flex-column flex mt8 list-mar-10">
          <OptimizeRoute
            outsideList={outsideList}
            errorDistanceList={errorDistanceList}
            booking={booking}
            locations={locations}
            isChecked={isOptimized}
            handleOptimize={this.handleOptimize}
            vehicleType={selectedVehicleType}
            roundTripDiscount={roundTripDiscount}
            isShowIcon
          />
          {this.showRoundTripDiscountCheckBox()
            && (
              <CheckBoxTemplate
                toggleRoundTrip={value => this.handleRoundTrip(value)}
                roundTripDiscount={(roundTripDiscount) || false}
                vehicleType={selectedVehicleType}
                isShowIcon
              />
            )
          }
        </div>
        {this.canAddLocation() && (
          <button
            type="button"
            className="with-right-icon Button Green-text-important No-Border-Important reset p No-Hover-Important"
            onClick={() => this.addLocation()}
          >
            {I18n.t('webapp.new_booking.step_1.add_destination')}
            <i className="xb material-icons Icon">
              add_circle
            </i>
          </button>
        )}
      </div>
    )
  }

  render() {
    const { isShopping, mapService, fullLoadView, isEnableGoogleMap, googleMap } = this.props

    return !!((isEnableGoogleMap && googleMap.map) || (!isEnableGoogleMap && mapService)) && (
      <div className="Locations Locations-Customs block">
        {this.renderPopupChangePickupLocation()}
        {this.renderLocationsCompletions()}
        {!isShopping && fullLoadView && this.renderLocationsActions()}
        {isShopping && fullLoadView && (
          <div className="block-item">
            <div className="min-w-24 mr10" />
            <span className="flex-index small-font default-smaller-font-color">
              {I18n.t('webapp.shopping.contactless_no_signature_required')}
            </span>
          </div>
        )}
      </div>
    )
  }
}

Locations.propTypes = {
  assignedDriver: PropTypes.shape({}),
  booking: PropTypes.shape({}),
  area: PropTypes.shape({}).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isHaveLHAddress: PropTypes.bool.isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  selectedServiceTypeID: PropTypes.number.isRequired,
  selectedVehicleTypeID: PropTypes.number,
  extraInfos: PropTypes.shape({}).isRequired,
  mapService: PropTypes.shape({}),
  googleMap: PropTypes.shape({}).isRequired,
  roundTripDiscount: PropTypes.bool.isRequired,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
  infoAssignDriverPopupActions: PropTypes.shape({}).isRequired,
  locationActions: PropTypes.shape({}).isRequired,
  roundTripDiscountActions: PropTypes.shape({}).isRequired,
  recentLocationsActions: PropTypes.shape({}).isRequired,
  isOptimized: PropTypes.bool.isRequired,
  timeType: PropTypes.string,
  selectedVehicleType: PropTypes.shape({}),
  isShopping: PropTypes.bool,
  timeTypeUI: PropTypes.string,
  currentStep: PropTypes.number,
  recentLocations: PropTypes.arrayOf(PropTypes.shape({})),
  fullLoadView: PropTypes.bool,
  outsideList: PropTypes.instanceOf(Array),
  errorDistanceList: PropTypes.instanceOf(Array),
  setOutsideList: PropTypes.func,
  setListErrorDistance: PropTypes.func,
  isShowPopupChangePickupLocation: PropTypes.bool,
  handleErrorPickupLocation: PropTypes.func,
  handleShowChangePickupLocation: PropTypes.func,
  isEnableGoogleMap: PropTypes.bool,
}

Locations.defaultProps = {
  assignedDriver: undefined,
  outsideList: [],
  errorDistanceList: [],
  booking: {},
  timeType: undefined,
  selectedVehicleType: undefined,
  isShopping: false,
  fullLoadView: true,
  timeTypeUI: undefined,
  currentStep: 0,
  setOutsideList: () => undefined,
  setListErrorDistance: () => undefined,
  handleErrorPickupLocation: () => undefined,
  handleShowChangePickupLocation: () => undefined,
  isShowPopupChangePickupLocation: false,
  selectedVehicleTypeID: null,
  mapService: null
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(Locations))
